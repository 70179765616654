import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel"
import { MallInvitationStyle } from "./style"
import { Button, Input, Pagination, Radio, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { hasToken, queryUrl } from "utils/functions/common";

import { useEffect, useState } from "react";
import { MallWorktypeListItem, PublicProductListItem } from "types/product";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetMallWorkTypeList, apiGetPublicProductList } from "api/shop";
import MarketProductCard from "components/product-card/market-product-card";
import { UserBaseInfo } from "types/user";
import { loginPathWithRedirect } from "utils/functions/user";

const MallInvitation = () => {
    const navigate = useNavigate()
    const sortField = new URLSearchParams(window.location.search).get("sortField") || "globalRank"
    const freeFee = new URLSearchParams(window.location.search).get("freeFee") || ""
    const pageNumber = new URLSearchParams(window.location.search).get("pageNumber") || ""
    const keyword = new URLSearchParams(window.location.search).get("keyword") || ""
    const workType = new URLSearchParams(window.location.search).get("workType") || ""
    const themeStyle = new URLSearchParams(window.location.search).get("themeStyle") || ""
    const [keywordValue, setKeywordValue] = useState("")
    const [loading, setLoading] = useState(false);
    const [categoryPid, setCategoryPid] = useState(0)
    const { Search } = Input;
    const pageSize = 48;
    const [productList, setProductList] = useState<PublicProductListItem[]>();
    const [themeStyleList, setThemeStyleList] = useState<{ name: string, id: string }[]>([])
    const [workTypeList, setWorkTyleList] = useState<MallWorktypeListItem[]>([])
    const [total, setTotal] = useState(0);
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    const onSearch = (e: string) => {
        const query = queryUrl({ keyword: e.toString() })
        navigate(`?${query}`)
    }

    useEffect(() => {
        apiGetMallProductListCategory({ type: 3, level: 1 }).then((res) => {
            const result = res.find(item => item.code === 'yuegao')
            if (!!result) {
                setCategoryPid(result.id)
            }
        });
    }, []);

    useEffect(() => {
        apiGetMallThemeStyleList().then(res => {
            const result: { name: string, id: string }[] = JSON.parse(JSON.stringify(res))
            result.forEach((item) => {
                item.id = String(item.id)
            })
            setThemeStyleList(result);
        })
    }, [])

    useEffect(() => {
        apiGetMallWorkTypeList().then(res => {
            setWorkTyleList(res);
        })
    }, []);

    const changePage = (e: number) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const query = queryUrl({ pageNumber: e })
        navigate(`?${query}`)
    };

    const checkWorkType = (value: string) => {
        const query = queryUrl({ workType: value, pageNumber: "" })
        navigate(`?${query}`)
    }

    const checkThemeStyle = (value: string) => {
        const query = queryUrl({ themeStyle: value, pageNumber: "" })
        navigate(`?${query}`)
    }


    useEffect(() => {
        if (!categoryPid) {
            return
        }
        let params: any = {
            pageNumber: pageNumber || 1,
            pageSize,
            sortField,
            keyword,
            freeFee: freeFee,
            categoryId: categoryPid
        };

        if (workType) {
            params.mallWorkTypeId = workType
        }

        if (themeStyle) {
            params.mallThemeStyleIds = themeStyle
        }

        setLoading(true);

        apiGetPublicProductList(params).then((res) => {
            setProductList(res.pageData);
            setLoading(false);
            setTotal(res.totalSize);
        });
    }, [keyword, workType, themeStyle, pageNumber, sortField, categoryPid, freeFee]);

    const token = hasToken()
    const toStoreList = () => {
        if(!token) {
            navigate(loginPathWithRedirect())
            return
        }
        navigate("/user-center/shop/commission/product-list")
    }

    return <MallInvitationStyle>
        <div className="guanggao-banner-wrapper">
            <GuanggaoBannerCarousel
                isAutoplay={true}
                positionCode={28}
            ></GuanggaoBannerCarousel>
        </div>
        <div className="top">
            <div className="title">G站约稿</div>
            <div className="word">GGAC约稿平台诚邀你一同开启精彩的艺术之旅，发现艺术新世界，释放你的无限可能。</div>
        </div>


        <div className="select first-select">
            <div className="row-2">
                作品类型:
                <div className={`tag ${!workType ? 'active' : ''}`} onClick={() => checkWorkType("")}>全部</div>
                {
                    workTypeList.map(item => {
                        return <div onClick={() => checkWorkType(String(item.id))} className={`tag ${Number(workType) === item.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                    })
                }
            </div>
        </div>
        <div className="select">
            <div className="row-2">
                题材风格:
                <div className={`tag ${!themeStyle ? 'active' : ''}`} onClick={() => checkThemeStyle("")}>全部</div>
                {
                    themeStyleList.map(item => {
                        return <div onClick={() => checkThemeStyle(String(item.id))} className={`tag ${themeStyle === item.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                    })
                }
            </div>
        </div>

        {
            <div className="sort">
                <div className="search-wrap">
                    {
                        userInfo && !!userInfo.shopId && <Button onClick={() => window.open("/user-center/shop/commission/product/create")} type="primary" className="upload-btn">发布约稿</Button>
                    }
                    {
                        ((userInfo && !userInfo.shopId) || !userInfo) && <Button onClick={() => toStoreList()} type="primary" className="upload-btn">发布约稿</Button>
                    }
                    <Search placeholder="请输入关键字搜索" onSearch={onSearch} style={{ width: 200 }} allowClear onChange={(e) => setKeywordValue(e.target.value)} value={keywordValue || ""} />
                </div>
            </div>
        }

        <Spin spinning={loading}>
            <div className="data-list">
                {productList &&
                    productList.map((item, index) => {
                        return <MarketProductCard item={item} key={index} />;
                    })}
            </div>
            {productList?.length === 0 && (
                <div style={{ padding: "130px 0" }}>
                    {/* <NoData /> */}
                    <div className="no-data">暂无此类型商品</div>
                    <div className="no-data jump-btn">
                        {
                            userInfo && userInfo.shopId && <Button
                                type="primary"
                                onClick={() => {
                                    window.open(
                                        `/user-center/shop/commission/product/create`
                                    );
                                }}
                            >
                                立即发布商品
                            </Button>
                        }
                        {
                            !userInfo || (userInfo && !userInfo.shopId) && <Button
                                type="primary"
                                onClick={() => {
                                    window.open(
                                        `/user-center/shop/commission/product-list`
                                    );
                                }}
                            >
                                立即发布商品
                            </Button>
                        }
                    </div>
                </div>
            )}
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "28px" }}
            hideOnSinglePage={true}
            current={Number(pageNumber) || 1}
            onChange={changePage}
            showSizeChanger={false}
            pageSize={pageSize}
            total={total}
        />
    </MallInvitationStyle>
}

export default MallInvitation